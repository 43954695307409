export default function ErrorPage({ status }) {
    const title = {
        503: '503: Server Probleme',
        500: '500: Server Fehler',
        404: '404: Seite nicht gefunden',
        403: '403: Verboten',
    }[status]

    const description = {
        503: 'Whoops, der Server hat gerade Probleme.',
        500: 'Whoops, da ging etwas schief.',
        404: 'Tut mir leid, die gesuchte Seite wurde nicht gefunden.',
        403: 'Du hast hier keinen Zugriff.',
    }[status]

    return (
        <div className={"flex items-center justify-center text-white flex-col h-[100vh]"}>
            <h1>{title}</h1>
            <div>{description}</div>
        </div>
    )
}
